<template>
  <footer class="footer">
    <div data-aos="fade-up">
      <div class="container">
        <div class="footer__top">
          <div class="footer-logo">
            <router-link :to="$localePath({ name: 'Home' })">
              <img :src="require('@/assets/img/logo.svg')" alt="" />
            </router-link>
          </div>
          <div class="footer-btn-block">
            <p>
              <span class="ic-mail"></span>
              <a
                class="color-white"
                target="_blank"
                href="mailto:support@ifsl-trades.com"
              >
                support@ifsl-trades.com
              </a>
            </p>
            <p>
              <span class="ic-telegram"></span>
              <a
                class="color-white"
                target="_blank"
                href="tg://resolve?domain=Ifslsup"
              >
                Support Telegram
              </a>
            </p>

            <router-link
              :to="$localePath({ name: 'Login' })"
              class="btn btn-white-outline"
            >
              {{ $t("btn.cabinet") }}
            </router-link>
          </div>
        </div>
        <div class="footer__body">
          <div class="footer-nav">
            <div class="row row-gap-30">
              <div class="col-xl-12">
                <ul class="footer-main-menu row row-gap-30">
                  <li class="col-6 col-lg-3">
                    <router-link
                      :to="$localePath({ name: 'TradeTerms' })"
                      class="main-menu__item"
                    >
                      {{ $t("menu.terms") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-end text-lg-center">
                    <router-link
                      :to="$localePath({ name: 'Markets' })"
                      class="main-menu__item"
                    >
                      {{ $t("menu.markets") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-start text-lg-center">
                    <router-link
                      :to="$localePath({ name: 'About' })"
                      class="main-menu__item"
                    >
                      {{ $t("menu.about") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-end">
                    <router-link
                      :to="$localePath({ name: 'Contacts' })"
                      class="main-menu__item"
                    >
                      {{ $t("menu.contacts") }}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="col-xl-12">
                <ul class="row row-gap-30 footer-nav__list list-inline">
                  <li class="col-6 col-lg-3">
                    <router-link
                      :to="$localePath({ name: 'Privacy', hash: '#tab3' })"
                    >
                      {{ $t("customer") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-end text-lg-center">
                    <router-link
                      :to="$localePath({ name: 'Privacy', hash: '#tab1' })"
                    >
                      {{ $t("privacy") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-start text-lg-center">
                    <router-link
                      :to="$localePath({ name: 'Privacy', hash: '#tab2' })"
                    >
                      {{ $t("refund") }}
                    </router-link>
                  </li>
                  <li class="col-6 col-lg-3 text-end">
                    <router-link :to="$localePath({ name: 'Faq' })">
                      {{ $t("subMenu.faq") }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <div class="container">
          <div class="footer-body__text">
            <p class="title">{{ $t("footerText.title") }}</p>
            <p class="title" v-html="$t('footerText.text')"></p>
          </div>

          <div class="footer__bottom">
            <p>{{ $t("footerCopy") }}</p>
            <p>{{ $t("footerFCA") }}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  i18n: {
    messages: {
      en: {
        footerText: {
          title: "Risk Warnings:",
          text: "Investing in high-risk categories such as Forex and Contracts for Difference (CFDs) is speculative and carries a high level of risk and may not be suitable for every investor. You may sustain a partial or full loss of your invested funds, so we do not recommend investing funds that you are not prepared to lose. It is important to understand the increased risks associated with the use of leverage. We strongly advise you to familiarize yourself with the terms and conditions of our website before using our service.",
        },
        footerCopy: "© 2025, iFSL Trade. All rights reserved",
        footerFCA: "FCA Reference ID: 841979",
      },
      ru: {
        footerText: {
          title: "Предупреждения о рисках:",
          text: "Инвестирование в высокорискованные группы, такие как Forex и контракты на разницу (CFD), является спекулятивной операцией, сопряженной с высоким уровнем риска, и может не подойти каждому инвестору. Вы можете столкнуться как с частичными, так и с полными потерями вложенных средств, поэтому мы не рекомендуем инвестировать средства, которые вы не готовы потерять. Важно осознавать повышенные риски, связанные с использованием кредитного плеча. Мы настоятельно советуем вам ознакомиться с условиями и услугами нашего сайта, прежде чем начать пользоваться нашим сервисом.",
        },
        footerCopy: "© 2025, iFSL Trade. Все права защищены",
        footerFCA: "FCA Reference ID: 841979",
      },
      fr: {
        footerText: {
          title: "Avertissements sur les risques:",
          text: "Investir dans des groupes à haut risque tels que le Forex et les contrats sur différence (CFD) est une activité spéculative qui implique un niveau de risque élevé et peut ne pas convenir à tous les investisseurs. Vous pourriez subir une perte partielle ou totale de votre investissement, nous vous déconseillons donc d'investir des fonds que vous n'êtes pas prêt à perdre. Il est important d'être conscient des risques accrus associés au recours à l'effet de levier. Nous vous conseillons fortement de lire les termes et conditions de notre site Web avant de commencer à utiliser notre service.",
        },
        footerCopy: "© 2025, iFSL Trade. Tous droits réservés",
        footerFCA: "FCA Reference ID: 841979",
      },
      de: {
        footerText: {
          title: "Risikowarnungen:",
          text: "Investitionen in Hochrisikogruppen wie Devisen und Differenzkontrakte (CFDs) sind eine spekulative Aktivität, die mit einem hohen Risiko verbunden ist und möglicherweise nicht für jeden Anleger geeignet ist. Es kann zu einem teilweisen oder vollständigen Verlust Ihrer Investition kommen. Wir empfehlen daher nicht, Gelder anzulegen, deren Verlust Sie nicht bereit sind. Es ist wichtig, sich der erhöhten Risiken bewusst zu sein, die mit dem Einsatz von Leverage verbunden sind. Wir empfehlen Ihnen dringend, die Allgemeinen Geschäftsbedingungen unserer Website zu lesen, bevor Sie mit der Nutzung unseres Dienstes beginnen.",
        },
        footerCopy: "© 2025, iFSL Trade. Alle Rechte vorbehalten",
        footerFCA: "FCA Reference ID: 841979",
      },
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  background: $secondary-color;
  color: $white;
}
.footer_bottom {
  background: $secondary-color-light;
}
.footer__top {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }
}
.footer-logo {
  img {
    width: auto;
    height: 40px;
  }
}
.footer-btn-block {
  display: flex;
  align-items: center;
  gap: 100px;

  @include media-breakpoint-down(lg) {
    gap: 50px;
  }
  @include media-breakpoint-down(lg) {
    gap: 30px;
    flex-direction: column;
    justify-content: center;
  }
}
.footer-nav {
  padding: 60px 0;
  border-top: 1px solid rgba($white, 0.1);
}
.footer-main-menu {
  border-bottom: 1px solid rgba($white, 0.1);
  padding-bottom: 30px;
}
.main-menu__item {
  text-transform: uppercase;
  font-weight: 700;
  color: $white;
}
.footer-nav__list {
  a {
    color: $white;
    &:hover {
      color: $white;
      text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &.list-inline {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
      > li {
        width: calc(50% - 10px);
      }
    }
  }
}
.footer-body__text {
  padding: 40px 0;
  font-size: 14px;

  .title {
    margin-bottom: 16px;
  }
  .text {
    color: rgba($white, 0.6);
  }
}
.footer__bottom {
  border-top: 1px solid rgba($white, 0.1);
  padding: 30px 0 40px;
  font-size: 12px;
  color: rgba($white, 0.6);
}
</style>
